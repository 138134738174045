import { FormikValues, useFormikContext } from 'formik';
import React, { FC, useState } from 'react';

import { API } from '@attentive/acore-utils';
import { useQuery } from '@attentive/data/react-query';
import { Banner, Box, LoadingIndicator, Stack, Text } from '@attentive/picnic';

import { getErrorMessage, useConfigureIntegrationMutation } from '../../api';
import { GoogleAdsAccountData, GoogleAdsSetupData, IntegrationFieldType } from '../../types';
import { IntegrationField } from '../IntegrationField';
import { DisconnectButton } from '../SetupConnectAndConfigure/DisconnectButton';
import { IntegrationForm } from '../SetupConnectAndConfigure/IntegrationForm';
import { SubmitButton } from '../SetupConnectAndConfigure/SubmitButton';

const PU_MAX_INPUT_WIDTH = '600px';
const PATH = '/integrations/gads/setup';

async function apiGet<T>(path: string) {
  const response = await API.get<T>(path);
  if (response.status >= 300) {
    throw new Error(getErrorMessage(response));
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return response.body!;
}

const fetchGoogleAccountSetupData = async (path?: string) => {
  // adding coalescing operator just to make linter happy. this path will never be null, and forcing a non-null assertion
  // doesn't appease the great, all-powerful, all-knowing linter.
  return await apiGet<GoogleAdsSetupData>(path ?? '');
};

const FieldEnabler: React.FC<{ arr: unknown; children: React.ReactNode }> = (props: {
  arr: unknown;
  children: React.ReactNode;
}) => {
  const isArray = Array.isArray(props.arr) && props.arr.length > 0;
  return (
    <Box
      css={{
        opacity: isArray ? '1' : '0.5',
        pointerEvents: isArray ? 'auto' : 'none',
        width: '100%',
        maxWidth: PU_MAX_INPUT_WIDTH,
      }}
    >
      {props.children}
    </Box>
  );
};

const AccountSelect: React.FC<{
  accounts: GoogleAdsAccountData[];
  setAccounts: React.Dispatch<React.SetStateAction<GoogleAdsAccountData[]>>;
  name: string;
  label: string;
  path?: string;
}> = (props: {
  accounts: GoogleAdsAccountData[];
  setAccounts: React.Dispatch<React.SetStateAction<GoogleAdsAccountData[]>>;
  name: string;
  label: string;
  path?: string;
}) => {
  const { data, isFetching, isLoading, isError } = useQuery([props.path], () =>
    fetchGoogleAccountSetupData(props.path)
  );

  // pulled this out because typescript warnings were yelling at the fact that the props object was not passed into the
  // React.useEffect dependencies. However, passing the entire object leads to an infinite update loop, and passing the specific property that
  // actually matters doesn't make the linter happy (its still looking for props to be in the dependencies list).
  // However, if you pull the object out into a variable and then pass that in, it no longer complains about the dependency list.
  // Yay dumb workarounds to make a linter happy!
  const dataSetter = props.setAccounts;

  React.useEffect(() => {
    dataSetter(data?.accounts || []);
  }, [data, dataSetter]);

  if (isFetching || isLoading) {
    return <LoadingIndicator />;
  }

  if (isError) {
    return (
      <Banner css={{ width: '100%' }} variant="error">
        {`Failed to fetch your Google Ads ${props.label} ID's. Please disconnect and reconnect the
          integration, or try again later`}
      </Banner>
    );
  }

  if (data?.accounts && data?.accounts.length === 0) {
    return (
      <Banner css={{ width: '100%' }} variant="error">
        {`No eligible ${props.label} ID's were found. Please double check your Google Ads account configurations and try again.`}
      </Banner>
    );
  }

  return (
    <FieldEnabler arr={props.accounts}>
      <IntegrationField
        type={IntegrationFieldType.SELECT}
        required={true}
        options={props.accounts.map((account) => {
          let label = `${account.id}`;
          if (account.name) {
            label = `${account.id}: ${account.name}`;
          }
          return { name: `${label} `, value: `${account.id}` };
        })}
        name={props.name}
        label={props.label}
        placeholder={`Select a ${props.label}`}
      />
    </FieldEnabler>
  );
};

interface SetupGoogleAdsSetupFormProps {
  onComplete: () => void;
}

export const SetupGoogleAdsSetupForm: FC<SetupGoogleAdsSetupFormProps> = ({ onComplete }) => {
  // IntegrationForm requires an initialValues set, even if its empty.
  const initialValues = {};
  const MANAGER_ACC_ID_NAME = 'managerAccountId';
  const SUB_ACC_ID_NAME = 'subAccountId';
  const validationConfig = {
    fields: {
      [MANAGER_ACC_ID_NAME]: { required: true },
      [SUB_ACC_ID_NAME]: { required: true },
    },
  };

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [managerAccounts, setManagerAccounts] = useState([] as GoogleAdsAccountData[]);
  const [subAccounts, setSubAccounts] = useState([] as GoogleAdsAccountData[]);
  const [subPath, setSubPath] = useState('');
  const [previousManagerAccount, setPreviousManagerAccount] = useState('');

  // Feels and looks like a hacky workaround but hey, this is what Formik themselves said to do:
  // https://formik.org/docs/api/useFormikContext

  const UpdateFieldsToken = () => {
    const { values } = useFormikContext();

    React.useEffect(() => {
      const currentAccountId = (values as FormikValues)[MANAGER_ACC_ID_NAME];
      const currentSubAccountId = (values as FormikValues)[SUB_ACC_ID_NAME];
      setSubmitDisabled(!currentAccountId || !currentSubAccountId);

      if (currentAccountId) {
        if (currentAccountId !== previousManagerAccount) {
          setPreviousManagerAccount(currentAccountId);
          (values as FormikValues)[SUB_ACC_ID_NAME] = '';
        }

        setSubPath(`${PATH}?account=${currentAccountId}`);
      }
    }, [values]);
    return null;
  };

  return (
    <IntegrationForm
      initialValues={initialValues}
      validationConfig={validationConfig}
      loadingText="Configuring"
      mutation={useConfigureIntegrationMutation}
      onComplete={onComplete}
    >
      <AccountSelect
        accounts={managerAccounts}
        setAccounts={setManagerAccounts}
        name={MANAGER_ACC_ID_NAME}
        label="Manager Account"
        path={PATH}
      />

      {!!subPath && (
        <AccountSelect
          accounts={subAccounts}
          setAccounts={setSubAccounts}
          name={SUB_ACC_ID_NAME}
          label="Sub Account"
          path={subPath}
        />
      )}

      <UpdateFieldsToken />
      <Text>
        Your Google Ads account has been connected. Please select a Manager account ID, and then an
        associated sub-account to link and click Submit to complete your installation.
        Alternatively, click the "Disconnect" button below to start over with a different account.
      </Text>
      <Stack direction="horizontal" spacing="$space6">
        <SubmitButton text="Submit" disabled={submitDisabled} />
        <DisconnectButton />
      </Stack>
    </IntegrationForm>
  );
};

/**
 * @generated SignedSource<<7cd09bb96e257fd12b5785321c918149>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IntegrationsCommonBodyQuery$variables = {
  companyId: string;
};
export type IntegrationsCommonBodyQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"IntegrationsCommonWebhookCallbackInstructionsFragment_query">;
};
export type IntegrationsCommonBodyQuery = {
  response: IntegrationsCommonBodyQuery$data;
  variables: IntegrationsCommonBodyQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "IntegrationsCommonBodyQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "IntegrationsCommonWebhookCallbackInstructionsFragment_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "IntegrationsCommonBodyQuery",
    "selections": [
      {
        "alias": "company",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "externalId",
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7c8ed9f1ffbd2a556ece22ffc1d41d7f",
    "id": null,
    "metadata": {},
    "name": "IntegrationsCommonBodyQuery",
    "operationKind": "query",
    "text": "query IntegrationsCommonBodyQuery(\n  $companyId: ID!\n) {\n  ...IntegrationsCommonWebhookCallbackInstructionsFragment_query\n}\n\nfragment IntegrationsCommonWebhookCallbackInstructionsFragment_query on Query {\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      externalId\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "90c35df4a8fdbd44dd0519d1fcc2c36a";

export default node;

/**
 * @generated SignedSource<<aa36a6d4db178b4570e7903f898ea86e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type StandardErrorStatus = "STANDARD_ERROR_STATUS_ABORTED" | "STANDARD_ERROR_STATUS_ALREADY_EXISTS" | "STANDARD_ERROR_STATUS_CANCELLED" | "STANDARD_ERROR_STATUS_DATA_LOSS" | "STANDARD_ERROR_STATUS_DEADLINE_EXCEEDED" | "STANDARD_ERROR_STATUS_FAILED_PRECONDITION" | "STANDARD_ERROR_STATUS_INTERNAL" | "STANDARD_ERROR_STATUS_INVALID_ARGUMENT" | "STANDARD_ERROR_STATUS_NOT_FOUND" | "STANDARD_ERROR_STATUS_NOT_SET" | "STANDARD_ERROR_STATUS_OUT_OF_RANGE" | "STANDARD_ERROR_STATUS_PERMISSION_DENIED" | "STANDARD_ERROR_STATUS_RESOURCE_EXHAUSTED" | "STANDARD_ERROR_STATUS_UNAUTHENTICATED" | "STANDARD_ERROR_STATUS_UNAVAILABLE" | "STANDARD_ERROR_STATUS_UNIMPLEMENTED" | "STANDARD_ERROR_STATUS_UNKNOWN" | "%future added value";
export type ResetServerSideTagEventsApiKeyInput = {
  companyId: string;
};
export type vendorBlotoutWhitelabelResetApiKeyMutation$variables = {
  input: ResetServerSideTagEventsApiKeyInput;
};
export type vendorBlotoutWhitelabelResetApiKeyMutation$data = {
  readonly resetServerSideTagEventsApiKey: {
    readonly __typename: "DefaultErrorFailure";
    readonly message: string;
    readonly status: StandardErrorStatus;
    readonly title: string;
    readonly traceId: string;
  } | {
    readonly __typename: "ResetServerSideTagEventsApiKeySuccess";
    readonly success: boolean;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type vendorBlotoutWhitelabelResetApiKeyMutation = {
  response: vendorBlotoutWhitelabelResetApiKeyMutation$data;
  variables: vendorBlotoutWhitelabelResetApiKeyMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "resetServerSideTagEventsApiKey",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "success",
            "storageKey": null
          }
        ],
        "type": "ResetServerSideTagEventsApiKeySuccess",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "traceId",
            "storageKey": null
          }
        ],
        "type": "DefaultErrorFailure",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "vendorBlotoutWhitelabelResetApiKeyMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "vendorBlotoutWhitelabelResetApiKeyMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9bcebd35ac1a7f0fc0693fde246dc68f",
    "id": null,
    "metadata": {},
    "name": "vendorBlotoutWhitelabelResetApiKeyMutation",
    "operationKind": "mutation",
    "text": "mutation vendorBlotoutWhitelabelResetApiKeyMutation(\n  $input: ResetServerSideTagEventsApiKeyInput!\n) {\n  resetServerSideTagEventsApiKey(input: $input) {\n    __typename\n    ... on ResetServerSideTagEventsApiKeySuccess {\n      success\n    }\n    ... on DefaultErrorFailure {\n      message\n      status\n      title\n      traceId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "542dc2ae800aad559a92f5fc531228e0";

export default node;

import React from 'react';

import cordialLogo from '../assets/vendor_logos/cordial.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import {
  VendorCategory,
  Vendor,
  VendorKey,
  IntegrationFieldType,
  CreativeSpecificDataKeyNames,
} from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

const cordialLocationList = [
  { name: 'admin.cordial.io', value: 'admin.cordial.io' },
  { name: 'usw2.admin.cordial.io', value: 'usw2.admin.cordial.io' },
  { name: 'use1.admin.cordial.io', value: 'use1.admin.cordial.io' },
];

export const cordial: Vendor = {
  logo: cordialLogo,
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  companyWebsite: 'https://cordial.com/',
  infoPagesEnabled: true,
  learnSubtext:
    'Grow your email list through Attentive sign-up forms and sync contacts to Cordial.',
  jointValueProp:
    "Grow your text marketing list and your email list at the same time using Attentive's best-in-class sign-up forms. Customers can opt into both email and text marketing in the same sign-up flow. Our integration ensures that emails will automatically sync to Cordial so that you can start sending marketing emails immediately. This integration will also help you keep your contact data and opt-in status up-to-date.",
  whatYoullNeedItems: ['A Cordial account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.SUBSCRIBERS,
      description:
        "Automatically sync subscriber's emails with Cordial so that you can start sending marketing emails immediately.",
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/360041419092',
      label: 'Cordial integration guide',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.CORDIAL}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'API Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'accountLocation',
            type: IntegrationFieldType.SELECT,
            label: 'Cordial Account Location',
            options: cordialLocationList,
            required: true,
          }),
          IntegrationField.Config({
            name: 'listNamesMulti',
            type: IntegrationFieldType.TEXT,
            label: 'List Names',
            subtext: 'For multiple lists, separate by commas',
            required: false,
          }),
          IntegrationField.Config({
            name: 'phoneKey',
            type: IntegrationFieldType.TEXT,
            label: 'Phone Attribute Name',
            required: false,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.CORDIAL}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'API Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'accountLocation',
            type: IntegrationFieldType.SELECT,
            label: 'Cordial Account Location',
            options: cordialLocationList,
            required: true,
          }),
          IntegrationField.Config({
            name: 'listNamesMulti',
            type: IntegrationFieldType.TEXT,
            label: 'List Names',
            subtext: 'For multiple lists, separate by commas',
            required: false,
          }),
          IntegrationField.Config({
            name: 'phoneKey',
            type: IntegrationFieldType.TEXT,
            label: 'Phone Attribute Name',
            required: false,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Forward emails',
            subtext: 'Forward any emails collected by Attentive to Cordial.',
          }),
        ]}
      />
    );
  },
  creativeSpecificLists: { type: 'compact', key: CreativeSpecificDataKeyNames.LISTS },
};

import React from 'react';

import placeholderLogo from '../assets/vendor_logos/placeholder.png';
import { SettingsStandard } from '../components/SettingsStandard';
import { AirshipSettingsForm } from '../components/SettingsStandard/AirshipSettingsForm';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import { VendorCategory, Vendor, VendorKey } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const vendorAirship: Vendor = {
  logo: placeholderLogo,
  category: VendorCategory.OTHER,
  whatYoullNeedItems: ['An Airship Account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.JOURNEYS,
      description:
        'Users can configure Attentive journeys to communicate in real-time to customers with Airship Push Notifications',
    },
  ],
  companyWebsite: 'https://airship.com/',
  salesEmail: '',
  infoPagesEnabled: false,
  learnSubtext: '',
  jointValueProp: '',
  helpLinks: [],
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_AIRSHIP}
        onComplete={onComplete}
        form={({ onComplete: onFormComplete }) => (
          <AirshipSettingsForm onComplete={onFormComplete} />
        )}
      />
    );
  },
  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_AIRSHIP}
        onComplete={onComplete}
        form={({ onFormComplete }) => (
          <AirshipSettingsForm
            onComplete={onFormComplete}
            superUserEnabled={superUserEnabled || false}
          />
        )}
      />
    );
  },
};

import pick from 'lodash/pick';
import React, { FC } from 'react';

import { Box } from '@attentive/picnic';

import { useUpdateIntegrationSettingsMutation } from '../../api';
import { useIntegrationContext } from '../../hooks/useIntegrationContext';
import { IntegrationFieldType, IntegrationFormFeatureValues } from '../../types';
import { IntegrationFeature } from '../IntegrationFeature';
import { IntegrationField } from '../IntegrationField';
import { IntegrationForm } from '../SetupConnectAndConfigure/IntegrationForm';
import { SubmitButton } from '../SetupConnectAndConfigure/SubmitButton';

import { CreateUserAttributesField } from './CreateUserAttributesFeature';

interface Props {
  onComplete: () => void;
  superUserEnabled?: boolean;
}

export const AirshipSettingsForm: FC<Props> = ({ onComplete }) => {
  const { integrationDetailValues } = useIntegrationContext();

  const validationConfig = {
    fields: {},
  };

  // pick out only the fields/features that this form actually shows from
  // the current state of what's in the backend. This ensures we only submit
  // the values that the form actually shows inputs for.
  const initialValues = pick(integrationDetailValues || {}, [
    'appId',
    'clientId',
    'clientSecret',
    'channelIdProperty',
    'THIRD_PARTY_PROCESSOR_FORWARDER',
  ]) as { [type: string]: IntegrationFormFeatureValues };

  initialValues.THIRD_PARTY_PROCESSOR_FORWARDER = pick(
    initialValues.THIRD_PARTY_PROCESSOR_FORWARDER,
    ['enabled']
  );

  return (
    <Box css={{ width: '100%' }}>
      <IntegrationForm
        loadingText="Saving"
        initialValues={initialValues}
        validationConfig={validationConfig}
        mutation={useUpdateIntegrationSettingsMutation}
        onComplete={onComplete}
      >
        <IntegrationFeature
          type="THIRD_PARTY_PROCESSOR_FORWARDER"
          label="Enable Journey Postback Feature"
          subtext=""
        />
        <IntegrationField
          type={IntegrationFieldType.PASSWORD}
          name="appId"
          label="Application ID"
          required={true}
          placeholder="appId"
        />
        <IntegrationField
          type={IntegrationFieldType.PASSWORD}
          name="clientId"
          label="Client ID"
          required={true}
          placeholder="clientId"
        />
        <IntegrationField
          type={IntegrationFieldType.PASSWORD}
          name="clientSecret"
          label="Client Secret"
          required={true}
          placeholder="clientSecret"
        />
        <CreateUserAttributesField
          fieldName="channelIdProperty"
          label="Channel ID Property"
          placeholder="Channel ID attribute"
          subtext=""
          required={true}
        />
        <SubmitButton text="Save changes" />
      </IntegrationForm>
    </Box>
  );
};

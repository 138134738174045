/**
 * @generated SignedSource<<9eb48bdfbdbbd9035e6b31ce0f92891b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type SegmentStatus = "SEGMENT_STATUS_ACTIVE" | "SEGMENT_STATUS_DELETED" | "SEGMENT_STATUS_INACTIVE" | "SEGMENT_STATUS_UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useSegmentsListCompanyFragment_company$data = {
  readonly id: string;
  readonly segments: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly internalId: number;
        readonly name: string;
        readonly status: SegmentStatus;
      };
    }>;
    readonly pageInfo: {
      readonly endCursor: string;
      readonly hasNextPage: boolean;
    };
  } | null;
  readonly " $fragmentType": "useSegmentsListCompanyFragment_company";
};
export type useSegmentsListCompanyFragment_company$key = {
  readonly " $data"?: useSegmentsListCompanyFragment_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSegmentsListCompanyFragment_company">;
};

import useSegmentsListCompanyFragmentRefetchQuery_graphql from './useSegmentsListCompanyFragmentRefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": useSegmentsListCompanyFragmentRefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "useSegmentsListCompanyFragment_company",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "after"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        }
      ],
      "concreteType": "SegmentConnection",
      "kind": "LinkedField",
      "name": "segments",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SegmentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Segment",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "internalId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};
})();

(node as any).hash = "5e5e2dfe63bfc1fc80f96891689cf834";

export default node;

/**
 * @generated SignedSource<<1da6774681350d862865ba375ec31e7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AdAccountSelectorQuery$variables = {
  companyId: string;
};
export type AdAccountSelectorQuery$data = {
  readonly company: {
    readonly companyVendorIntegrations?: ReadonlyArray<{
      readonly externalVendorData: {
        readonly data: {
          readonly adAccounts?: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
            readonly tosAccepted: boolean;
          }>;
        };
      } | null;
    }>;
  };
};
export type AdAccountSelectorQuery = {
  response: AdAccountSelectorQuery$data;
  variables: AdAccountSelectorQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "status",
    "value": "ENABLED"
  },
  {
    "kind": "Literal",
    "name": "vendor",
    "value": "VENDOR_FACEBOOK_ADS"
  }
],
v3 = [
  {
    "kind": "Literal",
    "name": "fields",
    "value": [
      "EXTERNAL_DATA_FIELD_FACEBOOK_ADS_AD_ACCOUNTS"
    ]
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FacebookAdAccount",
      "kind": "LinkedField",
      "name": "adAccounts",
      "plural": true,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tosAccepted",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "FacebookAdsExternalVendorData",
  "abstractKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdAccountSelectorQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": "company",
          "args": (v1/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "alias": null,
                    "args": (v2/*: any*/),
                    "concreteType": "CompanyVendorIntegration",
                    "kind": "LinkedField",
                    "name": "companyVendorIntegrations",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": (v3/*: any*/),
                        "concreteType": "ExternalVendorDataWrapper",
                        "kind": "LinkedField",
                        "name": "externalVendorData",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "data",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "externalVendorData(fields:[\"EXTERNAL_DATA_FIELD_FACEBOOK_ADS_AD_ACCOUNTS\"])"
                      }
                    ],
                    "storageKey": "companyVendorIntegrations(status:\"ENABLED\",vendor:\"VENDOR_FACEBOOK_ADS\")"
                  },
                  "action": "THROW",
                  "path": "company.companyVendorIntegrations"
                }
              ],
              "type": "Company",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "company"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdAccountSelectorQuery",
    "selections": [
      {
        "alias": "company",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CompanyVendorIntegration",
                "kind": "LinkedField",
                "name": "companyVendorIntegrations",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "concreteType": "ExternalVendorDataWrapper",
                    "kind": "LinkedField",
                    "name": "externalVendorData",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "data",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "externalVendorData(fields:[\"EXTERNAL_DATA_FIELD_FACEBOOK_ADS_AD_ACCOUNTS\"])"
                  },
                  (v4/*: any*/)
                ],
                "storageKey": "companyVendorIntegrations(status:\"ENABLED\",vendor:\"VENDOR_FACEBOOK_ADS\")"
              }
            ],
            "type": "Company",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3dc0e948670beb0a6e00a18b23846278",
    "id": null,
    "metadata": {},
    "name": "AdAccountSelectorQuery",
    "operationKind": "query",
    "text": "query AdAccountSelectorQuery(\n  $companyId: ID!\n) {\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      companyVendorIntegrations(status: ENABLED, vendor: \"VENDOR_FACEBOOK_ADS\") {\n        externalVendorData(fields: [EXTERNAL_DATA_FIELD_FACEBOOK_ADS_AD_ACCOUNTS]) {\n          data {\n            __typename\n            ... on FacebookAdsExternalVendorData {\n              adAccounts {\n                id\n                name\n                tosAccepted\n              }\n            }\n          }\n        }\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ee159be28742e770f1053429ef7f3d30";

export default node;

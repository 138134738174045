/**
 * @generated SignedSource<<94fa5f58e571f402565eab9652e45577>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MilestoneKey = "MILESTONE_KEY_ADDED_USER_ACCOUNTS" | "MILESTONE_KEY_COMPLETED_BRAND_STYLES" | "MILESTONE_KEY_COMPLETED_COMPANY_FORM" | "MILESTONE_KEY_COMPLETED_INTEGRATION_SURVEY" | "MILESTONE_KEY_COMPLETED_ONBOARDING" | "MILESTONE_KEY_COMPLETED_RTL_CREATIVE" | "MILESTONE_KEY_COMPLETED_WELCOME_JOURNEY" | "MILESTONE_KEY_DEFAULT_OFFER_REVIEWED" | "MILESTONE_KEY_ECOMMERCE_PLATFORM_COMPLETED" | "MILESTONE_KEY_EMAIL_CTA_FIRST_CLICKED" | "MILESTONE_KEY_EMAIL_FIRST_SENT" | "MILESTONE_KEY_ESP_INTEGRATION_COMPLETED" | "MILESTONE_KEY_IMPORTED_FROM_SALESFORCE" | "MILESTONE_KEY_INSTALLED_TAG" | "MILESTONE_KEY_SALESFORCE_CONVERTED_TO_PAYING" | "MILESTONE_KEY_SALESFORCE_GO_LIVE" | "MILESTONE_KEY_UNKNOWN" | "%future added value";
export type CompleteMilestonesRequest = {
  companyId: string;
  milestoneKeys?: ReadonlyArray<MilestoneKey> | null;
};
export type useSetMilestoneMutation$variables = {
  input: CompleteMilestonesRequest;
};
export type useSetMilestoneMutation$data = {
  readonly completeOnboardingMilestones: {
    readonly company: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"OnboardingManagerProviderFragment_company">;
    } | null;
  } | null;
};
export type useSetMilestoneMutation$rawResponse = {
  readonly completeOnboardingMilestones: {
    readonly company: {
      readonly attentiveDomain: string;
      readonly id: string;
      readonly milestones: {
        readonly milestones: ReadonlyArray<{
          readonly completed: SerializedDateTime | null;
          readonly key: MilestoneKey;
          readonly skipped: SerializedDateTime | null;
        }>;
      } | null;
      readonly onboardingSurveyOptions: {
        readonly selections: ReadonlyArray<{
          readonly optionKey: string | null;
          readonly selected: boolean;
        }>;
      } | null;
    } | null;
  } | null;
};
export type useSetMilestoneMutation = {
  rawResponse: useSetMilestoneMutation$rawResponse;
  response: useSetMilestoneMutation$data;
  variables: useSetMilestoneMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSetMilestoneMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CompleteMilestonesResponse",
        "kind": "LinkedField",
        "name": "completeOnboardingMilestones",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OnboardingManagerProviderFragment_company"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSetMilestoneMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CompleteMilestonesResponse",
        "kind": "LinkedField",
        "name": "completeOnboardingMilestones",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "attentiveDomain",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ListMilestonesResponse",
                "kind": "LinkedField",
                "name": "milestones",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Milestone",
                    "kind": "LinkedField",
                    "name": "milestones",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "key",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "completed",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "skipped",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ListOnboardingSurveySelectionsResponse",
                "kind": "LinkedField",
                "name": "onboardingSurveyOptions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OnboardingSurveySelection",
                    "kind": "LinkedField",
                    "name": "selections",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "selected",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "optionKey",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0034d87f20d7b476d453abdcd14e3c34",
    "id": null,
    "metadata": {},
    "name": "useSetMilestoneMutation",
    "operationKind": "mutation",
    "text": "mutation useSetMilestoneMutation(\n  $input: CompleteMilestonesRequest!\n) {\n  completeOnboardingMilestones(input: $input) {\n    company {\n      id\n      ...OnboardingManagerProviderFragment_company\n    }\n  }\n}\n\nfragment OnboardingManagerProviderFragment_company on Company {\n  id\n  attentiveDomain\n  milestones {\n    milestones {\n      key\n      completed\n      skipped\n    }\n  }\n  onboardingSurveyOptions {\n    selections {\n      selected\n      optionKey\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c86f2f2a6dae8a3f31f3a629e484f45a";

export default node;

import React from 'react';

import googleAdsLogo from '../assets/vendor_logos/googleads.png';
import {
  SetupGoogleAdsSteps,
  SetupGoogleAds,
  SetupGoogleAdsSettings,
} from '../components/SetupGoogleAds';
import { IntegrationContextProvider } from '../hooks';
import { VendorCategory, Vendor, VendorKey } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const vendorGoogleAds: Vendor = {
  logo: googleAdsLogo,
  category: VendorCategory.OTHER,
  infoPagesEnabled: true,
  setupComponent(onComplete) {
    return <SetupGoogleAds onComplete={onComplete} />;
  },
  setupComponentInternals(onComplete, extraText, _, oAuthState) {
    return (
      <IntegrationContextProvider vendorKey={VendorKey.VENDOR_GOOGLE_ADS}>
        <SetupGoogleAdsSteps
          onComplete={onComplete}
          extraText={extraText}
          oAuthState={oAuthState}
        />
      </IntegrationContextProvider>
    );
  },
  settingsComponent() {
    return <SetupGoogleAdsSettings />;
  },
  companyWebsite: 'https://ads.google.com/',
  learnSubtext: 'Google Ads provides a seamless way to sync your Google Ads data to Attentive.',
  jointValueProp:
    'Google Ads provides a seamless way to sync your Google Ads data to Attentive. With this integration, Attentive will leverage data from your Google Ads account such as ad headlines, descriptions, and more to enable more personalized messages.',
  associatedAttentiveFeatures: [
    // TODO: add real features and descriptions.
    {
      feature: ATTENTIVE_FEATURES.SUBSCRIBERS,
      description:
        'Improve subscriber targeting by allowing Attentive to leverage Google Ads data for improved marketing campaigns.',
    },
  ],
  helpLinks: [
    {
      href: 'https://ads.google.com/',
      label: 'Google Ads homepage',
    },
  ],
  whatYoullNeedItems: ['A Google Ads account.'],
  disconnectText: 'We will no longer be able to import your data from Google Ads.',
  disconnectConfirmationText:
    'Disconnecting will remove your Google Ads configurations and data. ' +
    'Reinstall the integration to reconnect.',
};

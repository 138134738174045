import React, { FC } from 'react';

import hubspotLogo from '../assets/vendor_logos/hubspot.png';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOauthConnectAndConfigure } from '../components/SetupOauthConnectAndConfigure';
import {
  VendorCategory,
  Vendor,
  VendorKey,
  IntegrationDetailsResponse,
  CreativeSpecificDataKeyNames,
} from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

interface Props {
  onComplete?: (integrationDetails: IntegrationDetailsResponse) => void;
}

const HubspotSetup: FC<Props> = () => {
  return (
    <SetupOauthConnectAndConfigure
      connectInstructions="Click the Connect button to link your Hubspot account to Attentive."
      vendorKey={VendorKey.HUBSPOT}
      configureFields={[]}
    />
  );
};

const HubspotConfigure: FC<Props> = ({ onComplete: onComplete }) => {
  return <SettingsStandard vendorKey={VendorKey.HUBSPOT} onComplete={onComplete} fields={[]} />;
};

export const hubspot: Vendor = {
  logo: hubspotLogo,
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  companyWebsite: 'https://www.hubspot.com/',
  infoPagesEnabled: true,
  errorReportingEnabled: true,
  learnSubtext:
    'Grow your email list through Attentive sign-up forms and sync contacts to HubSpot.',
  jointValueProp:
    "Grow your text marketing list and your email list at the same time using Attentive's best-in-class sign-up forms. Customers can opt into both email and text marketing in the same sign-up flow. Our integration ensures that emails will automatically sync to HubSpot so that you can start sending marketing emails immediately. This integration will also help you keep your contact data and opt-in status up-to-date.",
  whatYoullNeedItems: ['A HubSpot account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.SUBSCRIBERS,
      description:
        "Automatically sync subscriber's emails with HubSpot so that you can start sending marketing emails immediately.",
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/360041853551',
      label: 'HubSpot integration guide',
    },
  ],
  setupComponent(onComplete) {
    return <HubspotSetup onComplete={onComplete} />;
  },
  settingsComponent(onComplete) {
    return <HubspotConfigure onComplete={onComplete} />;
  },
  creativeSpecificSources: { type: 'compact', key: CreativeSpecificDataKeyNames.SOURCES },
};

/**
 * @generated SignedSource<<aedc89b222838f070b9fd770adf10e43>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IntegrationsCommonWebhookCallbackInstructionsFragment_query$data = {
  readonly company: {
    readonly externalId?: number;
  } | null;
  readonly " $fragmentType": "IntegrationsCommonWebhookCallbackInstructionsFragment_query";
};
export type IntegrationsCommonWebhookCallbackInstructionsFragment_query$key = {
  readonly " $data"?: IntegrationsCommonWebhookCallbackInstructionsFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"IntegrationsCommonWebhookCallbackInstructionsFragment_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "companyId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "IntegrationsCommonWebhookCallbackInstructionsFragment_query",
  "selections": [
    {
      "alias": "company",
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "companyId"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "externalId",
              "storageKey": null
            }
          ],
          "type": "Company",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "8f1bb19d3d8a73893dca47c9273ba862";

export default node;
